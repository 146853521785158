import React from 'react'
function PrivacyPage() {
  return (
    <>
      <section className='privacy'>
        <div className='container'>
          <div className='privacy__inner'>
            <h2>Privacy Policy</h2>
            <div className='privacy-info'>
              <span>OUR PRIVACY POLICY</span>
              <p>
                At Hommest Inc. (“Hommest”, “our”, “us”, “we”), we are committed
                to protecting your privacy and maintaining transparency
                regarding how we collect, use, and disclose your personal
                information. Hommest is an Ontario corporation. This Privacy
                Policy (the “Policy”) constitutes a legal, binding agreement
                between you (“you”, “your” or “user(s)”), a user of our
                websites, and Hommest. The purpose of this Privacy Policy is to
                inform you about Hommest's practices concerning the collection,
                handling, disclosure, and safeguarding of any personally
                identifiable information that may be provided through access to
                or use of our website, services, and publications, or that may
                otherwise be collected by us. By using our website or submitting
                information to our website at www.Hommest.com (the “Site”), you
                agree to the terms outlined in this Policy. This Policy applies
                only to individuals who wish to access our Site. If you do not
                wish to use our Services, please close this web tab and exit the
                Site. You hereby consent that Hommest may collect, use, and
                disclose such personal information (as defined below) in
                accordance with the following terms and conditions, as well as
                permitted or required by law.
              </p>

              <p>
                This Privacy Policy also explains how you can contact us if you
                have questions about, wish to make changes to, or want to delete
                any personal information that Hommest may be maintaining about
                you. We strongly recommend that you take the time to read this
                Privacy Policy and retain it for future reference. For the
                purposes of this Policy, the Site will serve as the initial
                point of contact between you and Hommest, as well as the primary
                source for any personal data you may provide us. Please read
                this Policy and our applicable Terms of Use, which are
                incorporated by reference, carefully.
              </p>
              <p>
                As used herein, the terms “use,” “collect,” “treat,” “process,”
                and “disclose,” when related to personal information and other
                data, encompass various activities, including the use of cookies
                on your device, as well as statistical and analytical
                compilation for the creation of aggregate databases and
                marketing strategies. This also includes, but is not limited to,
                the storage, utilization, combination, integration, disclosure,
                processing, and transfer of such personal information among our
                affiliates or with other third parties.
              </p>
              <span>COLLECTION OF PERSONAL INFORMATION</span>
              <p>
                For the purposes of this Privacy Policy, and in accordance with
                applicable privacy laws, "personally identifiable information"
                is defined as personal information about an identifiable
                individual, excluding legal entities, or any information that
                allows an individual to be personally identified. Public
                Content: We generally collect, use, and disclose personal
                information for use on the Site. Occasionally, we may request
                more detailed information from you and will inform you of the
                reasons for collecting it. Your account profile and any content
                you post on the Site are intended for public access and may be
                displayed on the Site or shared with third-party websites or
                services. Location & Activity: The personal information Hommest
                may collect about you includes, but is not limited to, your
                contact information (such as online name and neighborhood of
                residence), zip or postal code, date and time of your visit, IP
                address, content viewed, names of companies in your account,
                email address, and demographic information. Advertising: If you
                purchase advertising through our website, your billing
                information may be stored. This sensitive information is
                encrypted according to industry standards and is only shared
                with third parties who assist Hommest in completing the purchase
                transaction. Messaging: We may also retain communications you
                send us and other users, including email messages, forum posts,
                reviews, and responses we provide.
              </p>
              <span>CHILDREN AND MINORS</span>
              <p>
                Children and minors, defined as individuals under the age of 18,
                are not eligible to use the Site unsupervised. Therefore, we ask
                that children do not submit any personal information to us. If
                you are under 18, you may only use this Site in conjunction with
                and under the supervision of your parents or legal guardians.
              </p>
              <span>TREATMENT AND PURPOSE OF PERSONAL INFORMATION</span>
              <p>
                We use your personal information to operate and provide the
                products and services of Hommest on or in connection with the
                Site. Specifically, we generally collect, use, and disclose your
                personal information, with your consent, for the following
                purposes: To verify the validity of your email address and
                identity To communicate with you regarding information you
                submit to the Site To research, develop, manage, protect, and
                improve our communications To respond to questions and
                suggestions you submit to the Site To verify any reviews
                submitted to the Site To inform you about new products and
                services that may interest you To allow other users to
                communicate with you regarding quotes Additionally, we may use
                your personal information for the following purposes from time
                to time: To detect and protect Hommest and third parties against
                errors, fraud, theft, and other illegal activities, and to audit
                compliance with Hommest's policies and contractual obligations
                To understand your needs and preferences, including contacting
                and communicating with you, and conducting surveys, research,
                and evaluations For any other purpose we may indicate to you
                from time to time
              </p>
              <span>DISCLOSURE OF PERSONAL INFORMATION</span>
              <p>
                Except with your consent and as outlined below, we will not
                sell, license, trade, or rent your personal information to
                others. Third Parties: We may disclose personal information to
                our authorized third-party providers, including those engaged to
                assist us in delivering our services via the Site (such as
                software development, website hosting, data processing, document
                management, and office administration). These service providers
                are prohibited from using your personal information for any
                purpose other than to provide this assistance and must protect
                the personal information disclosed by Hommest in accordance with
                the privacy principles outlined in this Privacy Policy.
                Investigations: Hommest reserves the right to disclose personal
                information to a third party if required or authorized by law,
                regulation, search warrant, subpoena, or court order. Business
                Transfers: Hommest also reserves the right to disclose and/or
                transfer personal information to a third party in the event of a
                proposed or actual purchase of all or any portion of Hommest or
                any of its business or assets, ensuring you continue to receive
                the same products and services from the third party. Aggregate
                Information: Hommest collects aggregate information, which may
                be shared with third parties, such as advertisers. This
                information is typically collected for statistical purposes to
                understand current user interests. Social Media: The Site allows
                you to connect your account to social media platforms like
                Facebook, Twitter, and LinkedIn. Hommest will use this
                information to enable you to share public content with your
                friends and/or followers, provided you opt-in. Injured, Ill, or
                Deceased Individuals: Hommest will disclose personal information
                as required by law for the purpose of communicating with the
                next of kin or authorized representative of an injured, ill, or
                deceased individual. Legal Defense: We will release any and all
                collected information and/or personal data under the following
                circumstances: if required by applicable law, to defend
                ourselves and our affiliates from legal claims by third parties,
                to respond to such claims and legal processes (including
                takedown notices and subpoenas), to protect the property,
                rights, and integrity of Hommest or any third party, and to
                prevent or terminate any activity we consider to be illegal,
                unethical, or objectionable.
              </p>
              <span>KNOWLEDGE AND CONSENT</span>
              <p>
                Use of the Hommest website is voluntary. We will seek express
                consent for the use or disclosure of your personal information
                at the time of collection or when communicating with you. In
                rare circumstances, consent may be sought after the information
                has been collected but before use (for example, if we wish to
                use information for a purpose not previously identified). You
                may withdraw consent at any time, subject to legal or
                contractual restrictions and reasonable notice. If you refuse or
                withdraw your consent, we may not be able to provide or continue
                to provide certain services or information that may be valuable
                to you. We will not require you to consent to the collection,
                use, or disclosure of information beyond what is necessary to
                fulfill the explicitly specified and legitimate purposes for
                which the information is being provided.
              </p>
              <span>
                USE OR DISCLOSURE FOR MARKETING, ADVERTISING, AND SPONSORSHIP
                PURPOSES
              </span>
              <p>
                We may use your personal information to promote and market
                additional goods, services, and special offers from us, our
                affiliates, and sponsors, including through direct marketing. We
                may disclose aggregated statistics with anonymized data based on
                user interactions with the Site. To help advertisers understand
                our subscribers' interests, we will compile information to
                create general profiles of our subscribers' demographics and
                interests. However, we will never share any personally
                identifiable information with our advertisers. From time to
                time, we will request your express permission to receive
                Commercial Electronic Messages (CEMs), which we may send from or
                to Canadian computers. A CEM is an electronic message (e.g.,
                emails, SMS, social media messages, push notifications) sent to
                an electronic address (e.g., email, instant message accounts,
                smartphone, social media profile) containing a message asking
                recipients to engage in commercial activities (e.g., purchasing
                products and services). For the purposes of this Policy and in
                compliance with the Canada Anti-Spam Law (CASL), express consent
                requires a clear and concise description of the purpose for
                consent, along with details about the CEMs you may receive from
                us. Express consent for CEMs does not apply to messages that we
                may send that are: (i) opened or accessed outside of Canada;
                (ii) related to security, refunds, or guarantees of products and
                services on our Site; (iii) providing information about
                purchases and transactions regarding your account; or (iv)
                account-related notifications.
              </p>
              <p>
                Hommest may use proprietary software tools and third-party
                services, such as Google Analytics and AdSense (including
                remarketing code functionalities), to gather information about
                websites you visit or specific actions you take on the Site.
                Additionally, if you access the Site through a mobile device,
                you can use your device’s settings to control features such as
                location sharing, tailored advertising, and other privacy
                settings.
              </p>
              <span>COOKIES</span>
              <p>
                When you visit our Site, we may place a "cookie" in the browser
                directory of your computer's hard drive. A cookie is a small
                text file that a website can store on your web browser and later
                retrieve. Some cookies are temporary, while others can remain
                valid for several weeks or months, stored on your computer or
                mobile device even after you exit your browser. We also use web
                beacons, tags, and other means on our Site. These beacons are
                electronic files, sometimes just a pixel or small image,
                embedded on websites. We use cookies for various legitimate
                business purposes. In addition to the uses described in this
                Policy, we may utilize information from cookies and disclose it
                to third parties to measure Site usage, improve functionality
                and content, and facilitate user experience. You can adjust your
                browser settings to notify you when you receive a cookie or to
                refuse cookies altogether. Typically, this can be done through
                the settings tab of your browser.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { PrivacyPage }
