import React from 'react'

import decorYellow from '../assets/img/decor-yellow.svg'
function RequestPage() {
  return (
    <>
      <section
        className='request'
        style={{ backgroundImage: `url(${decorYellow})` }}
      >
        <div className='container'>
          <div className='head-text'>
            <h2>Your Service Request Was Sent!</h2>
            <div className='head-text-subtext'>
            We're matching you with qualified pros. You'll receive an email when they respond.
Complete your account setup to chat with pros in your HomeStars dashboard
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { RequestPage }
