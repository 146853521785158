import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as CheckCircle } from '../assets/img/svg/check-circle.svg'
import { ReactComponent as CheckCircleTwo } from '../assets/img/svg/check-circle-2.svg'

import { ReactComponent as Home } from '../assets/img/svg/Home.svg'
import { ReactComponent as Star } from '../assets/img/svg/Star.svg'
import { ReactComponent as Delete } from '../assets/img/svg/Delete.svg'
import { ReactComponent as Send } from '../assets/img/svg/Send.svg'
import decor from '../assets/img/decor.svg'

function HomePage() {
  return (
    <>
      <section className='header-banner'>
        <div className='header-banner-img'>
          <img src={require(`../assets/img/builder.png`)} alt='' />
        </div>
        <div className='container'>
          <div className='header-banner__inner'>
            <div className='header-banner-label'>
              <CheckCircle /> ESTABLISHED SINCE 2020
            </div>
            <h1>A New Solutions For Your Home Help</h1>
            <p>
              Discover a revolutionary way to manage your home tasks with our
              "Husband for an Hour" service. Whether you need help with small
              repairs, organizing, or even running errands, our dedicated
              professionals are here to assist you. Say goodbye to the hassle
              and hello to a more convenient, stress-free home life with
              "Husband for an Hour."
            </p>
            <NavLink className={'btn-big'} to='/order'>
              Order Now
            </NavLink>
          </div>
        </div>
      </section>
      <section className='experience'>
        <div className='container'>
          <div className='experience__inner'>
            <div className='experience-img'>
              <img src={require(`../assets/img/builder-2.png`)} alt='' />
            </div>
            <div className='experience-info'>
              <h3>We Are Very Experienced in Home Help Services</h3>
              <p>
                We bring a wealth of experience to our home help services,
                ensuring you receive top-notch assistance with all your
                household needs. From minor repairs and organization to errands
                and more, our skilled professionals are here to make your life
                easier. Trust our expertise to provide the reliable and
                efficient support you deserve.
              </p>
              <div className='experience-info-items'>
                <div className='experience-info-item'>
                  <CheckCircleTwo />
                  We are Committed
                </div>
                <div className='experience-info-item'>
                  <CheckCircleTwo />
                  Trusted Professionals
                </div>
                <div className='experience-info-item'>
                  <CheckCircleTwo />
                  Highly Rated Services
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='services'>
        <div className='container'>
          <div className='services__inner'>
            <div className='global-head'>
              <h2>Always Provide The Best Service</h2>
              <div className='global-head-text'>
                <div className='global-head-text-label'>Our Services</div>
                <p>
                  Our commitment is to always provide the best home help
                  service. With skilled professionals ready to assist with
                  repairs, organization, and everyday tasks, we ensure
                  top-quality support for your home. Experience the difference
                  with our reliable and efficient service.
                </p>
              </div>
            </div>
            <div className='services-items'>
              <div className='services-item'>
                <div className='services-item-icon'>
                  <Home />
                </div>
                <div className='services-item-title'>Organization</div>
                <p>
                  Expert assistance in decluttering and organizing your space.
                </p>
              </div>
              <div className='services-item'>
                <div className='services-item-icon'>
                  <Star />
                </div>
                <div className='services-item-title'>Minor Repairs</div>
                <p>
                  Quick and efficient solutions for small household repairs.
                </p>
              </div>
              <div className='services-item'>
                <div className='services-item-icon'>
                  <Delete />
                </div>
                <div className='services-item-title'>Handyman Tasks</div>
                <p>
                  Skilled professionals for various handyman jobs around your
                  home.
                </p>
              </div>
              <div className='services-item'>
                <div className='services-item-icon'>
                  <Send />
                </div>
                <div className='services-item-title'>Errands</div>
                <p>
                  Reliable help with shopping, deliveries, and other errands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pricing'>
        <div className='container'>
          <div className='pricing__inner'>
            <div className='global-head'>
              <h2>Choose Your Best Service Package </h2>
              <div className='global-head-text'>
                <div className='global-head-text-label'>Pricing List</div>
                <p>
                  Have many related needs, we present a suitable package for you
                  needs
                </p>
              </div>
            </div>
            <div className='pricing-items'>
              <div className='pricing-item active'>
                <div className='pricing-item-price'>
                  $99 <span>/Hour</span>
                </div>
                <div className='pricing-item-title'>Regular</div>
                <div className='pricing-item-text'>
                  Everything you need for your business identity
                </div>
                <ul>
                  <li>Electronic repairs</li>
                  <li>Junk removal</li>
                  <li>Window Cleaning</li>
                  <li>Carpet & rug cleaning </li>
                </ul>
                <NavLink className={'btn-small'} to='/'>
                  Choose Package
                </NavLink>
              </div>
              <div className='pricing-item '>
                <div className='pricing-item-price'>
                  $199 <span>/Hour</span>
                </div>
                <div className='pricing-item-title'>Deluxe</div>
                <div className='pricing-item-text'>
                  Everything you need for your business identity
                </div>
                <ul>
                  <li>Basement renovations</li>
                  <li>Bathroom Renovations</li>
                  <li>Blinds cleaning & repairing</li>
                  <li>Landscape lighting</li>
                </ul>
              </div>
              <div className='pricing-item '>
                <div className='pricing-item-price'>
                  $299 <span>/Hour</span>
                </div>
                <div className='pricing-item-title'>Superior</div>
                <div className='pricing-item-text'>
                  Everything you need for your business identity
                </div>
                <ul>
                  <li>Alarm systems</li>
                  <li>Fireplace mantels</li>
                  <li>Water filtration & purification</li>
                  <li>Solar water heating</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='banner' style={{backgroundImage: `url(${decor})`}}>
        <div className='container'>
          <div className='banner__inner'>
            <h2>Are You Ready To Work With Us Now?</h2>
            <NavLink className={'btn-big'} to='/order'>
              Order Now
            </NavLink>
          </div>
        </div>
      </section>
    </>
  )
}

export { HomePage }
