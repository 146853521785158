import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { ContactPage } from './pages/ContactPage'
import { PrivacyPage } from './pages/PrivacyPage'
import { TermsUsePage } from './pages/TermsUsePage'
import { OrderPage } from './pages/OrderPage'
import { RequestPage } from './pages/RequestPage'
import { NotfoundPage } from './pages/NotfoundPage'
import { Layout } from './components/Layout'

/**
 * In this project, I decided to use scss, so that I could easily type it
 * and have additional features like file separation and the use of constants
 */
import './assets/scss/style.scss'

/**
 * The NotfoundPage works as a 404 page, if the path is incorrect then it will not load this page.
 */
function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='privacy' element={<PrivacyPage />} />
          <Route path='terms-use' element={<TermsUsePage />} />
          <Route path='order' element={<OrderPage />} />
          <Route path='request' element={<RequestPage />} />

          <Route path='*' element={<NotfoundPage />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
