import React from 'react'
function TermsUsePage() {
  return (
    <>
      <section className='privacy'>
        <div className='container'>
          <div className='privacy__inner'>
            <h2>Terms of Use</h2>
            <div className='privacy-info'>
              <p>
                These Terms of Use constitute a valid and enforceable agreement
                between you (“you”, and also “your” or “yours”) and Hommest Inc.
                (“Hommest”). Throughout these Terms, the words “ourselves”,
                “us”, “we”, and “our(s)” will refer to Hommest, its parent,
                subsidiaries, assignees, successors, affiliates, and/or brands;
                and also our Services, as appropriate in the context of the use
                of such words. Your use of the Services is entirely at your own
                risk, for which we shall not be held liable. It is your
                responsibility to ensure that any Services or information
                available through the Services meet your specific purposes.
              </p>
              <p>
                Any failure on your part to comply with these Terms of Use may
                lead to Hommest: (i) prohibiting you from using the Services and
                any related privileges; or (ii) instituting any other remedies
                Hommest or its affiliates, at their sole discretion, deem
                appropriate. To prevent any breach of the Terms hereof and to
                enforce any remedy available at law, we shall take any
                technical, legal, or managerial measures that we deem, in our
                sole discretion, necessary and appropriate, without prior notice
                to you or any other user.
              </p>
              <p>
                Hommest's Privacy Policy and Hommest's SMS Short Code Terms of
                Service are incorporated herein by reference and form an
                integral part of these Terms. If you are a paid Service
                Professional, then your use of the Services will also be
                governed by the Hommest Service Professional Terms of Use.
              </p>
              <span>Services Access</span>
              <p>
                You agree that our Services may not always be error-free,
                continuous, or undisrupted. We may update, delete, disable,
                modify functionalities, or otherwise discontinue the Services,
                at our sole and final discretion, and we do not guarantee that
                it will always be available, work, or be accessible at any
                particular time. We cannot guarantee that the Services will work
                as advertised, or that they will give you the desired results.
                You agree not to distribute or make available any content found
                on our Services without our prior written authorization, unless
                such an act is done through sharing functionalities offered by
                our Service. You are expressly forbidden to make money off our
                Services unless you have received prior written permission from
                us. You agree not to access content through any technology or
                means other than through our Services or otherwise authorized by
                us.
              </p>
              <span>Compliance</span>
              <p>
                Hommest is not responsible for your violation of any laws while
                using our Services. You must comply with any and all local,
                provincial, or federal laws regarding your use of our Services,
                including, but not limited to, reviews and indications of any
                local service or establishment. We hereby reserve the right (but
                not the obligation) to remove and/or edit any content you or
                other users post on the Services, and we have the right (but not
                the obligation) to monitor and edit or remove any activity;
                thereby enacting the necessary measures to moderate any comments
                and to control user behavior within our Services.
              </p>
              <span>Communications from Hommest and Other Users</span>
              <p>
                By creating an account, you agree to receive certain
                communications from the Services regarding your account. For
                example, you will receive notifications on the status of your
                reviews, responses to inquiries from companies, and confirmation
                of account creation. Service Providers will receive
                notifications when they receive a review, email inquiries sent
                through Hommest, and confirmation of account creation. You can
                also subscribe to emails pertaining to promotions or services,
                as well as our email newsletter. You can opt out of
                non-essential communications at any time.
              </p>
              <span>Links to Third-Party Sites</span>
              <p>
                Our Services contain links to third-party sites. Hommest is not
                responsible for third-party linked sites. Your use of these
                sites is at your own risk, and Hommest is not responsible for
                Third-Party Content or changes to these sites. Hommest makes no
                endorsement about any other websites that you may access through
                our Services. The terms of use and privacy policies of those
                websites will likely differ from ours. It is your responsibility
                to review the terms of use and privacy policy of any Third-Party
                Sites.
              </p>
              <p>
                Our Services may contain materials, text, or information
                provided, published, or offered by third parties, including but
                not limited to advertisements, reviews, videos, or postings in
                online community discussions. You agree that Hommest shall not
                have any liability whatsoever to you for any such third-party
                material, data, or information.
              </p>
              <p>
                When you click on a link for another website, you are leaving
                Hommest, and we are not responsible.
              </p>
              <span>Updates</span>
              <p>
                Hommest hereby reserves the right to update, modify,
                discontinue, or terminate the Services at any time and at our
                sole and final discretion. Any changes to these Terms will be
                displayed in the Services, and we may notify you through the
                Services, by email, and/or short message service. Please refer
                to the date shown below for the date when effective changes were
                last undertaken. Your continued use of the Services after the
                last effective date of amendment to these Terms – either by
                registration or simple use – indicates your acceptance of any
                modifications thereto.
              </p>
              <span>Privacy</span>
              <p>
                You represent and warrant that you have read and acknowledged
                the terms of our Privacy Policy. Please take into account that
                we may need to disclose certain personally identifiable
                information about you, if we or our affiliates have a good faith
                belief that such a disclosure is necessary to: (i) take any
                action regarding factual or alleged illegal or inappropriate
                activities by you or any user of the Services; (ii) enforce or
                apply our Terms and Privacy Policy; (iii) comply with due legal
                process, judicial proceeding, or other valid government request
                served on us or our affiliates (e.g., subpoenas, search
                warrants); and/or (iv) protect our rights, goodwill, and assets,
                or that of our users, affiliates, or the general public.{' '}
              </p>
              <p>
                If you use the Services outside of Canada, you thereby consent
                to have your personally identifiable information transferred to
                and processed in Canada.
              </p>
              <span>Service Provider Reviews</span>
              <p>
                We provide Users with the ability to rate their experience(s)
                working with you via User ratings and reviews. Claimers also
                have the ability to solicit and collect ratings and reviews from
                any of their Users, which may or may not be displayed on their
                Company Profile, at Hommest’s sole discretion. You acknowledge
                and agree that Hommest has the right, in its sole discretion, to
                contact Users about services you provide to gain their feedback
                and rating of you, and to display such User ratings and reviews
                on our Services. You acknowledge and agree that Hommest is not
                responsible or liable to you for any comments, ratings, or
                communications of any kind from Users that we choose to post on
                our Services.
              </p>
              <span>Third Party Interactions with Service Provider</span>
              <p>
                You agree that we shall have the right to disclose any
                information we have regarding you and your company that we
                believe necessary to satisfy any law, regulation, or
                governmental request for information from us regarding any work
                or services you have performed for Users. You understand and
                agree that you are solely responsible for your interactions with
                us, other Users, or Service Providers. We reserve the right, but
                are under no obligation, to monitor disputes between you and
                other Users or Service Providers. You agree that if another User
                or Service Provider breaches any obligation to you, you are
                solely responsible for enforcing any right that you may have.
                Hommest is not responsible for enforcing any rights under a
                contract between you and another User, Member, Claimer, or
                Service Provider.
              </p>
              <span>Responsibility for Your Content</span>
              <p>
                If you provide written, verbal, telecommunication, audio, or
                video recordings, comments, or testimonials about our service(s)
                or products, you agree that we shall have sole ownership of any
                and all intellectual property rights in such comments or
                testimonials. We may post and publish your comments or portions
                thereof at our sole discretion on our Services or in marketing
                materials, including your name and company or agency, and you
                shall not be entitled to any payments associated with our use of
                the foregoing.
              </p>
              <span>Our Right to Use Service Provider Content</span>
              <p>
                You hereby authorize us to use your name, company name, and
                franchise name, as applicable, and any of the trademarks,
                service marks, trade names, and logos, content including
                photographs or audio-video recordings, in the form or format
                that you supply to us or that you upload to our Services. This
                includes use and posting on our Services and for use in
                marketing materials to be promoted to Users through online
                postings, via emails, or otherwise, to help promote you or your
                services, and you shall not be entitled to any payments
                associated with our use of the foregoing.
              </p>
              <span>Service Provider Ownership</span>
              <p>
                You represent that you hold all necessary rights to display any
                of the logos, service marks, trademarks, and any other content
                that you upload to our Services, and you represent that your
                uploading for display of any such content, and the use by
                Hommest of such content, as contemplated by this Agreement,
                shall not violate any third party's intellectual property
                rights. If there are any limitations or restrictions pertaining
                to the use or presentation of such logos, trade or service
                marks, it shall be your responsibility to provide us, in
                writing, with any such restrictions or limitations of use.
              </p>
              <span>
                Service Provider Content Does Not Reflect the Opinion of Hommest
              </span>
              <p>
                Hommest reserves the right, in its sole discretion, to review,
                reject, and remove any Content that you upload to the Services
                or your Company Profile. You represent and warrant that all of
                the information you provide to Hommest, including any and all
                information you include on your Company Profile, is true and
                accurate. You agree to maintain and update such information to
                keep it accurate. You acknowledge that a violation of any of the
                foregoing could result in significant damages, and you agree
                that you are liable to Hommest for any such damages, and will
                indemnify Hommest in the event of any third-party claims against
                Hommest based on or arising from your violation of the
                foregoing.
              </p>
              <p>
                If you provide any information that is inaccurate, or Hommest
                has reasonable grounds to believe is inaccurate, Hommest may
                suspend or terminate your use of the Services and/or Membership
                and/or decline to permit your continued and/or future use of the
                Services and/or future Membership. You hereby understand that
                your Company Profile will include any predecessor or successor
                entities of your company, and any other company which has the
                same majority shareholders or partners of your company may be
                considered as an affiliate company that could be referenced on
                your Company Profile.
              </p>
              <span>Hommest Ownership</span>
              <p>
                We own the Hommest Content, including but not limited to visual
                interfaces and graphics, interactive features, design features,
                compilation, products, computer code, software, Company/User
                reviews and ratings, badges, awards, and any and all other
                multimedia elements and/or components of the Services, excluding
                your Service Provider Content, User Content, and any Third Party
                Content displayed therein. We own the copyrights, trademarks,
                trade names, service marks, and other intellectual and
                proprietary rights associated with the Hommest Content and the
                Services throughout the world, which are protected under
                copyright, trade dress, patent, trademark laws, and any and all
                other applicable intellectual and proprietary rights.
              </p>
              <p>
                As such, you may not reproduce, modify, create derivative works
                or adaptations thereof, distribute, publicly display, or in any
                way exploit any of the Hommest Content, in whole or in part,
                except as expressly authorized by us or our affiliates. Except
                as expressly set forth herein, we do not grant you any express
                or implied rights, authorizations, or licenses, and any and all
                rights in and to the Services and the Hommest Content are ours.
                The use of the Hommest logo, best of award, and verification
                badge is subject to Hommest’s terms and conditions, and Hommest
                has the sole discretion to restrict your use of Hommest Content.
              </p>
              <span>Our Right to Use Your Content</span>

              <p>
                We may use your Content in many different ways. This includes
                publicly displaying it, reformatting it, incorporating it into
                advertisements, distributing it, and allowing others to do the
                same on their own websites and media platforms. You hereby
                irrevocably grant us royalty-free, perpetual, irrevocable,
                non-exclusive, worldwide rights and license to use your Content
                for any purpose. You represent and warrant to Hommest that you
                have all the rights, licenses, and authorizations necessary to
                grant such license, thereby irrevocably granting the users of
                the Services and any third party publicly accessing the Services
                the right to access your Content in connection with their use of
                the Services and any service thereon.
              </p>
              <p>
                Finally, you irrevocably waive, and cause to be waived, against
                Hommest and its users any claims and assertions of moral rights
                or attribution with respect to your Content. By "use," we mean
                use, copy, publicly perform or display, distribute, modify,
                translate, and create derivative works of your Content.
              </p>
              <span>Ownership</span>
              <p>
                You own your Content, and we own the Hommest Content, including
                but not limited to visual interfaces and graphics, interactive
                features, design features, compilation, products, computer code,
                software, Company/User reviews and ratings, and any and all
                other multimedia elements and/or components of the Services,
                excluding your Content, User Content, and any Third Party
                Content displayed therein.{' '}
              </p>
              <p>
                We own the copyrights, trademarks, trade names, service marks,
                and other intellectual and proprietary rights associated with
                the Hommest Content and the Services throughout the world, which
                are protected under copyright, trade dress, patent, trademark
                laws, and any and all other applicable intellectual and
                proprietary rights. As such, you may not reproduce, modify,
                create derivative works or adaptations thereof, distribute,
                publicly display, or in any way exploit any of the Hommest
                Content, in whole or in part, except as expressly authorized by
                us or our affiliates.{' '}
              </p>
              <p>
                Except as expressly set forth herein, we do not grant you any
                express or implied rights, authorizations, or licenses, and any
                and all rights in and to the Services and the Hommest Content
                are ours.
              </p>
              <span>User Content Does Not Reflect the Opinion of Hommest</span>
              <p>
                At our discretion, and without any notice to you, Hommest
                reserves the right to remove or reinstate User Content. For
                example, we may remove User Content, correct a Company's rating,
                reject subsequent submissions from you, disable your account, or
                block your IP address. Hommest does not take responsibility for
                the accuracy or completeness of User Content. You are
                responsible for using your judgment when determining the
                accuracy, completeness, or usefulness of any information,
                advice, opinions, or any other content found on the Services.
              </p>
              <p>
                We have no obligation to retain or provide you with copies of
                your Content, nor do we guarantee any confidentiality with
                respect to your Content.
              </p>
              <span>Spam</span>
              <p>
                Hommest and its affiliates take spam seriously, and we encourage
                you and our users to report any spam activities to us. We will
                not tolerate, nor allow others to use any information from the
                Services for the transmission of unsolicited bulk communication
                to any of our users or to any third party. You may not access
                the Services to harvest and/or collect any information about our
                users for any purpose. Any commercial communication that you may
                receive from us and/or our partners, licensors, suppliers, and
                affiliates will clearly indicate measures to stop receiving such
                communications, including unsubscribe links and pertinent
                instructions.
              </p>
              <span>Limited License and Copyright</span>
              <p>
                Subject to these Terms, you grant Hommest a non-exclusive,
                non-transferable, limited right to access, use, and display the
                Services and the visible text, graphics, or images thereon (the
                “Materials”) and to view and download the Materials only in
                connection with your personal and non-commercial use of the
                Services. This authorization does not constitute a transfer of
                title in the Materials and is subject to the restrictions
                outlined in these Terms.
              </p>
              <span>Trade-marks</span>
              <p>
                The trade-marks, logos, and company names of Hommest or any of
                its affiliates used on the Services may not be copied, imitated,
                or used, in whole or in part, without the prior written consent
                of Hommest or any such affiliate. Other products, services,
                logos, and company names mentioned on the Services may be the
                trade-marks of their respective owners. Except as expressly
                provided herein, Hommest and its affiliates do not grant any
                express or implied right or license to you under any
                intellectual property right, including any patent, trade-mark,
                copyright, trade secret, or confidential information.
              </p>
              <span>Non-infringement</span>
              <p>
                You may not post or otherwise make available to the Services any
                material that is protected by a copyright, trade-mark, or other
                proprietary right without the express permission of the owner.
                You agree to assume sole liability and to indemnify Hommest for
                any damage resulting from infringement of any third party's
                copyrights, trade-marks, or other proprietary rights, or any
                other harm resulting from your use of such infringing materials
                in a review, or on or in connection with the Services.
              </p>
              <span>LIMITATIONS ON LIABILITY AND DISCLAIMERS</span>
              <p>
                In no event shall Hommest or its affiliates, agents, licensors,
                suppliers, or their respective directors, officers, or employees
                be liable to you for any direct, indirect, incidental, special,
                punitive, or consequential damages whatsoever, whether based on
                warranty, contract, tort, or any other legal theory, even if
                advised of the possibility of such damages. This limitation of
                liability shall apply to the fullest extent permitted by law in
                the applicable jurisdiction. Hommest's maximum cumulative
                liability to you for any losses or damages arising out of or in
                connection with your access to the Services shall be limited to:
                (i) the amount paid, if any, by you to us in connection with the
                Services during the 12 months prior to the action giving rise to
                such liability; or (ii) CDN$50 (fifty Canadian dollars),
                whichever is lesser. Your use of the Services is at your own
                risk, and Hommest assumes no liability or responsibility
                regarding the content, your use of the Services, or the receipt,
                storage, transmission, or other use of your personal
                information.
              </p>
              <span>Disclaimer of Information Accuracy</span>
              <p>
                The materials and information accessible through our Services
                may contain inaccuracies and typographical errors. Hommest and
                its affiliates make no representations or warranties about the
                accuracy or completeness of the materials or information
                available on or through our Services, or the reliability of any
                advice, opinion, statement, or other information displayed or
                distributed through our Services. Hommest assumes no
                responsibility for the accuracy and completeness of any review.
                You acknowledge that any reliance on the foregoing is at your
                sole risk. Hommest will not be responsible to you or any third
                party for any damages, expenditures, loss of profits, or
                prospective profits of any kind arising out of or related to
                your use of the Services.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { TermsUsePage }
